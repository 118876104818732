export const PROJECT_LIST = [
    {
        projectId: 1,
        projectTitle: 'Car Viewer',
        projectGithubLink: 'https://github.com/shshamim63/car-viewer-react',
        deployLink: 'https://car-viewer.netlify.com/',
        description: 'Is a Web app that interacts with API and renders fetched data.',
        imageSource: "carviewer.png",
        technologies: ["React and Redux"],
    },
    {
        projectId: 2,
        projectTitle: 'Battleship',
        deployLink: 'https://readytobattle.netlify.com/',
        description: 'Battleship is a strategy type guessing game.',
        projectGithubLink: 'https://github.com/shshamim63/Battleship_Game',
        imageSource: "battleship.png",
        technologies: ["JavaScript", "HTML5", "CSS3", "jest"],
    },
    {
        projectId: 3,
        projectTitle: 'Calculator',
        deployLink: 'https://minimagic-calculator.netlify.com/',
        description: 'An app that performs basic mathematics calculation',
        projectGithubLink: 'https://github.com/shshamim63/react-calculator',
        imageSource: "calculator.png",
        technologies: ["Reactjs"],
    },
    {
        projectId: 4,
        projectTitle: 'Car Viewer API',
        deployLink: 'https://car-viewer-api.herokuapp.com/',
        description: 'An API(Application Program Interface) build with Ruby on Rails.',
        projectGithubLink: 'https://github.com/shshamim63/car-viewer-api',
        imageSource: "api.jpg",
        technologies: ["Ruby On Rails"],
    },
    {
        projectId: 5,
        projectTitle: 'Tic-Tac-Toe',
        deployLink: 'tic-tac-toe-web.netlify.com',
        description: 'A web based game for two players',
        projectGithubLink: 'https://github.com/shshamim63/Tic-Tac-Toe',
        imageSource: "tictactoe.png",
        technologies: ["JavaScript", "HTML5", "CSS3"],
    }
];
